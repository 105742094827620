import React from "react";

function Meal() {
  return (
    <svg
      width="36pt"
      height="36pt"
      className="ma-lr"
      fill="#519332"
      viewBox="0 -31 512 512"
    >
      <g>
        <g>
          <path
            d="M240,80c-88.366,0-160,71.634-160,160s71.634,160,160,160s160-71.634,160-160C399.899,151.676,328.324,80.101,240,80z&#xD;&#xA;&#x9;&#x9;&#x9; M240,384c-79.529,0-144-64.471-144-144S160.471,96,240,96s144,64.471,144,144C383.907,319.491,319.491,383.907,240,384z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M467.864,49.52c-7.338-3.058-15.797-1.376-21.408,4.256C426.891,73.241,415.925,99.722,416,127.32V232&#xD;&#xA;&#x9;&#x9;&#x9;c0.042,10.134,6.445,19.15,16,22.528V408c0,13.255,10.745,24,24,24s24-10.745,24-24V67.672&#xD;&#xA;&#x9;&#x9;&#x9;C480.016,59.718,475.22,52.545,467.864,49.52z M464,408c0,4.418-3.582,8-8,8s-8-3.582-8-8V256h16V408z M464,240h-24&#xD;&#xA;&#x9;&#x9;&#x9;c-4.418,0-8-3.582-8-8V127.32c-0.06-23.351,9.221-45.756,25.776-62.224c0.645-0.69,1.544-1.086,2.488-1.096&#xD;&#xA;&#x9;&#x9;&#x9;c0.512,0.006,1.017,0.112,1.488,0.312c1.393,0.526,2.296,1.88,2.248,3.368V240z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M176,184c-17.673,0-32,14.327-32,32s14.327,32,32,32s32-14.327,32-32S193.673,184,176,184z M176,232&#xD;&#xA;&#x9;&#x9;&#x9;c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16S184.837,232,176,232z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M240.688,189.376l-14.064-14.064c-1.688-1.679-2.633-3.964-2.624-6.344c-0.018-13.782-11.186-24.95-24.968-24.968h-32&#xD;&#xA;&#x9;&#x9;&#x9;c-6.619,0.017-12.963,2.644-17.656,7.312l-30.064,30.064c-4.698,4.672-7.331,11.03-7.312,17.656v33.936&#xD;&#xA;&#x9;&#x9;&#x9;c-0.019,6.626,2.614,12.984,7.312,17.656l14.064,14.064c1.688,1.679,2.633,3.964,2.624,6.344&#xD;&#xA;&#x9;&#x9;&#x9;c0.018,13.782,11.186,24.95,24.968,24.968h16c6.619-0.017,12.963-2.645,17.656-7.312l22.064-22.064&#xD;&#xA;&#x9;&#x9;&#x9;c1.688-1.674,3.967-2.617,6.344-2.624c13.782-0.018,24.95-11.186,24.968-24.968v-32&#xD;&#xA;&#x9;&#x9;&#x9;C248.019,200.406,245.386,194.048,240.688,189.376z M232,239.032c-0.004,4.951-4.017,8.964-8.968,8.968&#xD;&#xA;&#x9;&#x9;&#x9;c-6.619,0.017-12.963,2.644-17.656,7.312l-22.064,22.064c-1.688,1.674-3.967,2.617-6.344,2.624h-16&#xD;&#xA;&#x9;&#x9;&#x9;c-4.951-0.004-8.964-4.017-8.968-8.968c0.019-6.626-2.614-12.984-7.312-17.656l-14.064-14.064&#xD;&#xA;&#x9;&#x9;&#x9;c-1.688-1.679-2.633-3.964-2.624-6.344v-33.936c-0.008-2.38,0.936-4.665,2.624-6.344l30.064-30.064&#xD;&#xA;&#x9;&#x9;&#x9;c1.688-1.674,3.967-2.617,6.344-2.624h32c4.951,0.004,8.964,4.017,8.968,8.968c-0.019,6.626,2.614,12.984,7.312,17.656&#xD;&#xA;&#x9;&#x9;&#x9;l14.064,14.064c1.688,1.679,2.633,3.964,2.624,6.344V239.032z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M317.656,258.344l-32-32c-3.125-3.124-8.19-3.123-11.314,0.002c-1.499,1.5-2.342,3.534-2.342,5.654v16&#xD;&#xA;&#x9;&#x9;&#x9;c0,4.418-3.582,8-8,8c-13.255,0-24,10.745-24,24c0,4.418-3.582,8-8,8c-13.255,0-24,10.745-24,24c0,4.418-3.582,8-8,8h-16&#xD;&#xA;&#x9;&#x9;&#x9;c-4.418,0.001-7.999,3.583-7.998,8.002c0,2.121,0.843,4.154,2.342,5.654l32,32c1.5,1.5,3.534,2.344,5.656,2.344h16&#xD;&#xA;&#x9;&#x9;&#x9;c13.255,0,24-10.745,24-24c0-4.418,3.582-8,8-8c13.255,0,24-10.745,24-24c0-4.418,3.582-8,8-8c13.255,0,24-10.745,24-24v-16&#xD;&#xA;&#x9;&#x9;&#x9;C320,261.878,319.156,259.844,317.656,258.344z M304,280c0,4.418-3.582,8-8,8c-13.255,0-24,10.745-24,24c0,4.418-3.582,8-8,8&#xD;&#xA;&#x9;&#x9;&#x9;c-13.255,0-24,10.745-24,24c0,4.418-3.582,8-8,8h-12.688l-16.2-16.2c11.951-1.563,20.889-11.747,20.888-23.8c0-4.418,3.582-8,8-8&#xD;&#xA;&#x9;&#x9;&#x9;c13.255,0,24-10.745,24-24c0-4.418,3.582-8,8-8c12.053,0.001,22.237-8.937,23.8-20.888l16.2,16.2V280z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M359.032,195.712l-1.376-1.368l-40-40l-33.376-33.368c-11.963-11.958-31.354-11.955-43.312,0.008&#xD;&#xA;&#x9;&#x9;&#x9;c-11.958,11.962-11.954,31.354,0.008,43.312l1.376,1.368l40,40l33.376,33.368c11.963,11.958,31.354,11.954,43.312-0.008&#xD;&#xA;&#x9;&#x9;&#x9;C370.998,227.061,370.995,207.67,359.032,195.712z M253.264,131.392c2.619-2.183,5.919-3.383,9.328-3.392h0.032&#xD;&#xA;&#x9;&#x9;&#x9;c3.876,0.012,7.592,1.55,10.344,4.28l19.72,19.72h-41.296C246.218,145.792,247.056,136.566,253.264,131.392z M267.312,168h41.376&#xD;&#xA;&#x9;&#x9;&#x9;l24,24h-41.376L267.312,168z M346.736,228.608c-2.619,2.183-5.919,3.383-9.328,3.392h-0.032&#xD;&#xA;&#x9;&#x9;&#x9;c-3.878-0.013-7.594-1.553-10.344-4.288L307.312,208h41.296C353.782,214.208,352.944,223.434,346.736,228.608z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M64,48v64H48V48H32v64H16V48H0v104c0.039,12.523,5.965,24.299,16,31.792V408c0,13.255,10.745,24,24,24s24-10.745,24-24&#xD;&#xA;&#x9;&#x9;&#x9;V183.792c10.035-7.493,15.961-19.269,16-31.792V48H64z M48,408c0,4.418-3.582,8-8,8s-8-3.582-8-8V192h16V408z M64,152&#xD;&#xA;&#x9;&#x9;&#x9;c0,13.255-10.745,24-24,24s-24-10.745-24-24v-24h48V152z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
}

export default Meal;
