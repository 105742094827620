import React from "react";

function Drinks() {
  return (
    <svg
      width="36pt"
      height="36pt"
      className="ma-lr"
      fill="#519332"
      viewBox="0 -31 512 512"
    >
      <path d="M24,216V472a24.027,24.027,0,0,0,24,24H160a24.027,24.027,0,0,0,24-24V216a7.976,7.976,0,0,0-2.347-5.661l-44.577-44.577C139.5,157.63,144,140.78,144,128c0-10.418-2.993-23.536-5.41-32.437A8,8,0,0,0,144,88V40a24.028,24.028,0,0,0-24-24H88A24.028,24.028,0,0,0,64,40V88a8,8,0,0,0,5.41,7.563C66.993,104.464,64,117.582,64,128c0,12.78,4.5,29.63,6.924,37.762L26.347,210.339A7.976,7.976,0,0,0,24,216ZM80,40a8.009,8.009,0,0,1,8-8h32a8.009,8.009,0,0,1,8,8v8H80Zm0,24h48V80H80Zm5.913,32h36.176c2.236,7.673,5.911,21.833,5.911,32,0,10.143-3.678,24.319-5.913,32H85.911C83.675,152.327,80,138.167,80,128,80,117.857,83.678,103.681,85.913,96Zm-2.6,80h41.374l32,32H51.313ZM40,224H168v16H40Zm0,32H168V432H40Zm0,192H168v24a8.009,8.009,0,0,1-8,8H48a8.009,8.009,0,0,1-8-8Z" />
      <path d="M200,264V471.507A24.52,24.52,0,0,0,224.492,496h95.016A24.52,24.52,0,0,0,344,471.507V264a71.6,71.6,0,0,0-24.724-54.284L304.266,89.627A23.94,23.94,0,0,0,312,72V64a8,8,0,0,0,7.761-9.94l-4.97-19.881A23.967,23.967,0,0,0,291.508,16H252.492a23.966,23.966,0,0,0-23.283,18.179l-4.97,19.881A8,8,0,0,0,232,64v8a23.937,23.937,0,0,0,7.735,17.627L224.723,209.716A71.6,71.6,0,0,0,200,264ZM244.731,38.06A7.99,7.99,0,0,1,252.492,32h39.016a7.99,7.99,0,0,1,7.762,6.06L301.754,48H242.246ZM296,64v8a8.009,8.009,0,0,1-8,8H256a8.009,8.009,0,0,1-8-8V64ZM237.241,220.112a8,8,0,0,0,2.967-5.275L255.065,95.976c.311.012.621.024.935.024h32c.313,0,.624-.012.935-.024l14.857,118.861a8,8,0,0,0,2.967,5.275A55.737,55.737,0,0,1,328,264V471.507A8.5,8.5,0,0,1,319.508,480H224.492A8.5,8.5,0,0,1,216,471.507V264A55.737,55.737,0,0,1,237.241,220.112Z" />
      <path d="M360,240V472a24.027,24.027,0,0,0,24,24h80a24.027,24.027,0,0,0,24-24V240a64.365,64.365,0,0,0-32-55.427V96h8a8,8,0,0,0,8-8V56a8,8,0,0,0-8-8V40a24.028,24.028,0,0,0-24-24H408a24.028,24.028,0,0,0-24,24v8a8,8,0,0,0-8,8V88a8,8,0,0,0,8,8h8v88.573A64.365,64.365,0,0,0,360,240ZM472,472a8.009,8.009,0,0,1-8,8H384a8.009,8.009,0,0,1-8-8V448h96Zm0-232V432H376V240ZM400,40a8.009,8.009,0,0,1,8-8h32a8.009,8.009,0,0,1,8,8v8H400Zm-8,24h64V80H392Zm48,32v48H408V96ZM403.434,196.623A8,8,0,0,0,408,189.4V160h32v29.4a8,8,0,0,0,4.566,7.227A48.262,48.262,0,0,1,469.217,224H378.783A48.262,48.262,0,0,1,403.434,196.623Z" />
      <path d="M104,272a32.036,32.036,0,0,0-32,32v80a32,32,0,0,0,64,0V304A32.036,32.036,0,0,0,104,272Zm16,112a16,16,0,0,1-32,0V304a16,16,0,0,1,32,0Z" />
      <path d="M272,416.262c11.84,0,22.5-8.279,30.018-23.312,6.606-13.213,10.245-30.6,10.245-48.95s-3.639-35.737-10.245-48.95C294.5,280.017,283.84,271.738,272,271.738s-22.5,8.279-30.018,23.312c-6.606,13.213-10.244,30.6-10.244,48.95s3.638,35.737,10.244,48.95C249.5,407.983,260.16,416.262,272,416.262Zm0-128c11.2,0,23.737,23.837,23.737,55.738S283.205,399.738,272,399.738,248.262,375.9,248.262,344,260.8,288.262,272,288.262Z" />
    </svg>
  );
}

export default Drinks;
