var module = {
  fn: {
    locale: "fn",
    messages: {
      topbar_title: "Bienvenue dans notre magasin {theme_name}",
      call_us: "Appelez nous",
      home: "maison",
      features: "fonctionnalités",
      new: "Nouveau",
      blog: "Blog",
      blog_left_sidebar: "barre latérale gauche",
      blog_right_sidebar: "barre latérale droite",
      blog_detail: "détail du blog",
      category_left_sidebar: "barre latérale gauche",
      category_right_sidebar: "barre latérale droite",
      category_no_sidebar: "sans encadré",
      category_metro: "métro",
      category_full_width: "pleine largeur",
      products: "des produits",
      left_sidebar: "Barre latérale degauche",
      right_sidebar: "Barre latérale droite",
      no_sidebar: "pas de barre latérale",
      three_col_thumbnail_left: "3 vignettes gauche",
      three_col_thumbnail_right: "3 vignette droite",
      thumbnail_below: "vignette ci_dessous",
      accordian_details: "détails accordéon",
      thumbnail_left: "vignette à gauche",
      thumbnail_right: "vignette droite",
      vertical_tab: "onglet vertical",
      pages: "des pages",
      about_us: "à propos de nous",
      lookbook: "lookbook",
      login: "sidentifier",
      register: "registre",
      logout: "Connectez _ Out",
      search: "chercher",
      wishlist: "liste de souhaits",
      cart: "Chariot",
      collection: "collection",
      forget_password: "mot de passe oublié",
      contact: "contact",
      checkout: "check_out",
      compare: "comparer",
      order_success: "succès de la",
      dashboard: "Tableau de bord",
      FAQ: "FAQ",
      shop: "Boutique",
      mens_fashion: "la mode masculine",
      sports_wear: "vêtements de sport",
      top: "Haut",
      bottom: "bas",
      ethic_wear: "usure éthique",
      shirts: "chemises",
      women_fashion: "la mode des femmes",
      dresses: "les robes",
      skirts: "jupes",
      westarn_wear: "usure occidentale",
      ethnic_wear: "tenue éthnique",
      sport_wear: "vêtement de sport",
      bottom_wear: "usure du bas",
      kids_fashion: "mode pour enfants",
      accessories: "Accessories",
      fashion_jewellery: "bijoux fantaisie",
      caps_and_hats: "casquettes et chapeaux",
      precious_jewellery: "bijoux précieux",
      necklaces: "colliers",
      earrings: "des boucles doreilles",
      rings_wrist_wear: "bagues et vêtements",
      men_accessories: "accessoires pour hommes",
      ties: "liens",
      cufflinks: "boutons de manchette",
      pockets_squares: "poches à carreaux",
      helmets: "casques",
      scarves: "écharpes",
      phone_cases: "Coque de téléphone",
      my_account: "Mon compte",
      fashion: "Mode",
      beauty: "Beauté",
      electronic: "Électronique",
      furniture: "Meubles",
      kids: "des gamins",
      pets: "animaux domestiques",
      vegetables: "Des légumes",
      watch: "Regarder",
      theme_elements: "éléments de thème",
      element_title: "titre de l'élément",
      collection_banner: "bannière de collection",
      home_slider: "home curseur",
      category: "Catégorie",
      service: "un service",
      image_size_ratio: "rapport de taille d'image",
      product_elements: "éléments de produit",
      product_box: "boîte de produit",
      product_slider: "curseur de produit",
      no_slider: "pas de curseur",
      multi_slider: "curseur multiple",
      tab: "languette",
      email_template: "modèle de courrier électronique",
      portfolio: "portefeuille",
      portfolio_grid_2: "portefeuille grille 2",
      portfolio_grid_3: "portefeuille grille 3",
      portfolio_grid_4: "portefeuille grille 4",
      portfolio_masonary_2: "maçonnerie 2",
      portfolio_masonary_3: "maçonnerie 3",
      portfolio_masonary_4: "maçonnerie 4",
      portfolio_masonary_full: "maçonnerie pleine largeur",
      meat: "",
      fish: "",
      dishes: "",
      fruits_vegetables: "",
      bread: "",
      drinks: "",
    },
  },
  en: {
    locale: "en_US",
    messages: {
      topbar_title: "Welcome to Our store Multikart",
      call_us: "Call Us",
      home: "home",
      features: "features",
      new: "new",
      blog: "blog",
      blog_left_sidebar: "left sidebar",
      blog_right_sidebar: "right sidebar",
      blog_detail: "blog detail",
      category_left_sidebar: "left sidebar",
      category_right_sidebar: "right sidebar",
      category_no_sidebar: "no sidebar",
      category_metro: "metro",
      category_full_width: "full width",
      products: "products",
      left_sidebar: "left sidebar",
      right_sidebar: "right sidebar",
      no_sidebar: "no sidebar",
      three_col_thumbnail_left: "Thumbnail left",
      three_col_thumbnail_right: "Thumbnail right",
      thumbnail_below: "thumbnail below",
      accordian_details: "accordian details",
      thumbnail_left: "image left",
      thumbnail_right: "image right",
      vertical_tab: "vertical tab",
      pages: "pages",
      about_us: "about us",
      lookbook: "lookbook",
      login: "login",
      register: "register",
      logout: "Logout",
      search: "search",
      wishlist: "wishlist",
      cart: "cart",
      collection: "collection",
      forget_password: "forget password",
      contact: "contact",
      checkout: "checkout",
      compare: "compare",
      order_success: "order_success",
      dashboard: "Dashboard",
      FAQ: "FAQ",
      shop: "Shop",
      mens_fashion: "mens fashion",
      top: "top",
      bottom: "bottom",
      ethic_wear: "ethic wear",
      sports_wear: "sports wear",
      shirts: "shirts",
      women_fashion: "womens fashion",
      dresses: "dresses",
      skirts: "skirts",
      westarn_wear: "westarn wear",
      ethnic_wear: "ethnic wear",
      sport_wear: "aport wear",
      bottom_wear: "bottom wear",
      kids_fashion: "kidss fashion",
      accessories: "Accessories",
      fashion_jewellery: "Fashion Jewellery",
      caps_and_hats: "caps and hats",
      precious_jewellery: "precious jewellery",
      necklaces: "necklaces",
      earrings: "earrings",
      rings_wrist_wear: "rings & wrist wear",
      men_accessories: "mens accessories",
      ties: "ties",
      cufflinks: "cufflinks",
      pockets_squares: "pockets squares",
      helmets: "helmets",
      scarves: "scarves",
      phone_cases: "phone cases",
      my_account: "My Account",
      fashion: "Fashion",
      beauty: "Beauty",
      electronic: "Electronic",
      furniture: "Furniture",
      kids: "Kids",
      pets: "Pets",
      vegetables: "Vegetables",
      watch: "Watch",
      theme_elements: "theme elements",
      element_title: "element title",
      collection_banner: "collection banner",
      home_slider: "home slider",
      category: "category",
      service: "service",
      image_size_ratio: "image size ratio",
      product_elements: "product elements",
      product_box: "product box",
      product_slider: "product slider",
      no_slider: "no slider",
      multi_slider: "multi slider",
      tab: "tab",
      email_template: "email template",
      portfolio: "portfolio",
      portfolio_grid_2: "portfolio grid 2",
      portfolio_grid_3: "portfolio grid 3",
      portfolio_grid_4: "portfolio grid 4",
      portfolio_masonary_2: "masonary 2",
      portfolio_masonary_3: "masonary 3",
      portfolio_masonary_4: "masonary 4",
      portfolio_masonary_full: "masonary full width",
      meat: "meat",
      fish: "fish",
      dishes: "dishes",
      fruits_vegetables: "greens",
      bread: "bread",
      drinks: "drinks",

      title_web: "Del Prado |  Online Store",
      discount_10: "save 10%",
      own_meat: "Own butchery",
      shop_now: "Buy Now !",
      discount_15: "save 15%",
      receive_home: "Recive it in your home",
      ask_here: "ask here",
      home_delivery: "Home delivery",
      more_than_50: "since 50 eur",
      own_cattle: "Own Cattle",
      maximum_quality: "Maximum quality and warranty",
      organic_products: "Organic products",
      traditional_way: "Grow in the traditional way",
      banner_date: "2020",
      buy_online: "buy online",
      with_confidence: "with the confidence that provides buying near your",
      special_products: "Highlighted products",

      on_sale: "on Sale",
      product_details: "product details",
      quantity: "quantity",
      add_cart: "add to the cart",
      view_details: "view details",

      free_delivery: "Free delivery",
      from_30: "Since 30 eur",
      _24_7: "24 X 7 Service",
      buy_whenever: "Buy at any time",
      subscribe_sales: "Subscribe to our sales",
      sales_customers: "Special sales exclusively for customers",
      pay_online: "Pay online",
      safety_warranted: "Warranted safe",

      details: "Details",
      video: "Video",
      write_review: "Write Review",
      reviews: "Reviews",
      rating: "Rating",
      name: "Name",
      email: "Email",
      review_title: "Review title",
      review: "review",
      submit_review: "Submit Review",
      no_reviews: "There is no reviews",
      buy_now: "Buy  Now !",
      share_it: "Share it !",
      add_whislist: "Add to whislist",
      showing_products: "Show products",
      result: "result",
      sorting_items: "Sorting items",
      price_high_2_low: "Price: from High to Low",
      price_low_2_high: "Price: from Low to High",
      new_items: "New Products",
      sort_A_2_Z: "Sort: from A to Z",
      sort_Z_2_A: "Sort: from Z to A",
      back: "Back",
      filter: "filter",

      loading: "Loading",
      new_product: "New product",

      close: "Close",
      signup: "Signup as a client",

      image: "Image",
      product_name: "Prodcut name",
      price: "Price",
      availability: "Availability",
      action: "Action",
      in_stock: "In stock",
      continue_shopping: "Continue Shopping",
      check_out: "Check out",
      whishList_empty: "Wishlist is empty",
      explore: "Explore more shortlist  items.",

      related_products: "Related products",

      seen_all: " Ups!  You have seen it all.",
      couldnt_find: "Sorry !! We couldn't find what you are looking for ",
      check_misspelt:
        "Please check if it's well typed down or try with a new word",
      description: "Description",
      no_description: "No descripcion",
      compare_empty: "Empty compare list",

      thank_you: "Thank you",
      payment_received: "The payment has been reveived successfully",
      transaction_id: "Transaction ID:",
      order_details: "Order details:",
      subtotal: "Subtotal",
      shipping: "Shipping:",
      tax: "tax(GST)",
      total: "total",
      payment_information: "Payment information:",
      payer_id: "Client ID:",
      payment_id: "Payment ID:",
      payment_token: "Payment Token:",
      order_id: "Order ID:",
      oder_date: "Order Fecha:",
      order_total: "Order Total",
      shipping_address: "Shipping Address",
      contact_phone: "Contact Phone:",
      payment_method: "Payment method:",
      banking_acceptance:
        "Acceptance of the delivery is subject to the avilability of the item",
      delivery_date: "Extimated delivery date",

      total_price: "Total price:",

      empty_cart: "Your Cart is Empty",

      card_details: "Cart details",

      introduce_credit: "Input card details",

      expiration: "Expiration date",
      cvc: "CVC",
      pay: "Pay",

      billing: "Billing details",
      first_name: "Name",
      last_name: "Last name",
      phone: "Phone",
      email_address: "Email address",
      country: "Country",
      address: "Address",
      city: "Town/City",
      state: "Provincia",
      postal: "Postal code",
      create_question: "create account?",
      free_shipping: "Free delivery",
      local_pickup: "Pickup at the shop",

      submit: "Submit",

      subscribe_newsletter: "Subscribe to our sales",

      about_text:
        "Alimentacion Del Prado is for decades delivering and trading products of the highgest quality,backed for the most demanding standards. Also distributting another products ,all of them with warranted qality.",
      information: "Information",
      safe_buy: "Safe buy",

      address_text:
        " Del Prado Alimentacion c/ Armando Palacio Valdés , 10  (45,67 km) 33600 Mieres",

      language: "language",
      spanish: "Spanish",
      english: "English",
      currency: "Currency",

      page_not_found: "Page not found",

      update_parameter: "Update account parameters",
      user_name: "User name",

      contact_us: "Contact us",
      electronic_contact: "Electronic address",
      issue: "Issue",
      message_input: "Write here your message",

      back_home: "Back home",
      buy_safely: "Buy in a simple and safely way",
      follow_steps: "Follow these 3 simple steps ....",
      select_products: "select products",
      realize_payment: "Realice the payment",
      recive_products: "Recibae your products",

      new_customers: "New customers",
      create_account: "Create A Account",
      create_account_text:
        " Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.",
      password: "Contrasena",
      forget_question: "Forgot password?",
    },
  },

  es: {
    locale: "es_ES",
    messages: {
      topbar_title: "Bienvenido a Del Prado",
      call_us: "Llamanos",
      home: "inicio",
      features: "aplicaciones",
      new: "nuevo",
      blog: "blog",
      blog_left_sidebar: "left sidebar",
      blog_right_sidebar: "right sidebar",
      blog_detail: "blog detail",
      category_left_sidebar: "left sidebar",
      category_right_sidebar: "right sidebar",
      category_no_sidebar: "no sidebar",
      category_metro: "metro",
      category_full_width: "full width",
      products: "productos",
      left_sidebar: "left sidebar",
      right_sidebar: "right sidebar",
      no_sidebar: "no sidebar",
      three_col_thumbnail_left: "Thumbnail left",
      three_col_thumbnail_right: "Thumbnail right",
      thumbnail_below: "thumbnail below",
      accordian_details: "accordian details",
      thumbnail_left: "image left",
      thumbnail_right: "image right",
      vertical_tab: "vertical tab",
      pages: "paginas",
      about_us: "acerca de nosotros",
      lookbook: "lookbook",
      login: "Comenzar sesion",
      register: "register",
      logout: "Logout",
      search: "busqueda",
      wishlist: "lista de deseos",
      cart: "carrito",
      collection: "coleccion",
      forget_password: "olvido la contrasena",
      contact: "contacto",
      checkout: "pagar en caja",
      compare: "comparar",
      order_success: "pedido realizado satisfactoriamente",
      dashboard: "Panel de control",
      FAQ: "FAQ",
      shop: "Tienda",
      mens_fashion: "mens fashion",
      top: "top",
      bottom: "bottom",
      ethic_wear: "ethic wear",
      sports_wear: "sports wear",
      shirts: "shirts",
      women_fashion: "womens fashion",
      dresses: "dresses",
      skirts: "skirts",
      westarn_wear: "westarn wear",
      ethnic_wear: "ethnic wear",
      sport_wear: "aport wear",
      bottom_wear: "bottom wear",
      kids_fashion: "kidss fashion",
      accessories: "Accessories",
      fashion_jewellery: "Fashion Jewellery",
      caps_and_hats: "caps and hats",
      precious_jewellery: "precious jewellery",
      necklaces: "necklaces",
      earrings: "earrings",
      rings_wrist_wear: "rings & wrist wear",
      men_accessories: "mens accessories",
      ties: "ties",
      cufflinks: "cufflinks",
      pockets_squares: "pockets squares",
      helmets: "helmets",
      scarves: "scarves",
      phone_cases: "phone cases",
      my_account: "My Account",
      fashion: "Fashion",
      beauty: "Beauty",
      electronic: "Electronic",
      furniture: "Furniture",
      kids: "Kids",
      pets: "Pets",
      vegetables: "Vegetables",
      watch: "Watch",
      theme_elements: "theme elements",
      element_title: "element title",
      collection_banner: "collection banner",
      home_slider: "home slider",
      category: "categoria",
      service: "servicio",
      image_size_ratio: "image size ratio",
      product_elements: "product elements",
      product_box: "product box",
      product_slider: "product slider",
      no_slider: "no slider",
      multi_slider: "multi slider",
      tab: "tab",
      email_template: "email template",
      portfolio: "portfolio",
      portfolio_grid_2: "portfolio grid 2",
      portfolio_grid_3: "portfolio grid 3",
      portfolio_grid_4: "portfolio grid 4",
      portfolio_masonary_2: "masonary 2",
      portfolio_masonary_3: "masonary 3",
      portfolio_masonary_4: "masonary 4",
      portfolio_masonary_full: "masonary full width",
      meat: "carniceria",
      fish: "pescaderia",
      dishes: "platos",
      fruits_vegetables: "fruteria",
      bread: "pan",
      drinks: "bebidas",

      aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: "////////////////////////",

      title_web: "Del Prado | Comercio Online",
      discount_10: "ahorre 10%",
      own_meat: "embutidos propios",
      shop_now: "compre ya !",
      discount_15: "ahorre 15%",
      receive_home: "recibe la compra en tu hogar",
      ask_here: "consulte aqui",
      home_delivery: "Envio a domicilio",
      more_than_50: "En compras superiores a 50 euros",
      own_cattle: "Ganaderia Propia",
      maximum_quality: "La maxima garantia y calidad",
      organic_products: "Productos organicos",
      traditional_way: "Cultivado de la manera tradicional",
      banner_date: "2020",
      buy_online: "compra online",
      with_confidence: "con la confianza que da la cercania",
      special_products: "productos destacados",
      on_sale: "en oferta",
      product_details: "detalles del producto",
      add_cart: "anadir al carrito",
      view_details: "ver detalles",

      free_delivery: "Entrega Gratuita",
      from_30: "Apartir de 30eur",
      _24_7: "Servivio 24 X 7 ",
      buy_whenever: "Compre a cualquier hora",
      subscribe_sales: "Subscribete a las ofertas",
      sales_customers: "Ofertas especiales para socios",
      pay_online: "Pago online",
      safety_warranted: "Seguridad garantizada.",

      details: "Detalles",
      video: "Video",
      write_review: "Escribir opinion",
      reviews: "Opiniones",
      rating: "votos",
      name: "Nombre",
      email: "Email",
      review_title: "Titulo de la opinion",
      review: "Opinion",
      submit_review: "Enviar opinion",
      no_reviews: "No hay ninguna opinion.",
      quantity: "Cantidad",
      buy_now: "Compre Ya !",
      share_it: "Comparte",
      add_whislist: "Anadir a la lista deseos",
      showing_products: "Mostrando productos",
      result: "Resultado",
      sorting_items: "Ordernar productos",
      price_high_2_low: "Precio: de mas caro a mas barato",
      price_low_2_high: "Precio: de mas barato a mas caro",
      new_items: "Nuevos productos",
      sort_A_2_Z: "Ordenar: de A a Z",
      sort_Z_2_A: "Ordenar: de Z a A",
      back: "Atras",
      filter: "filtro",

      loading: "Cargando",
      new_product: "Nuevo Producto",

      close: "Cerrar",
      signup: "Darse de alta como cliente",

      image: "Imagen",
      product_name: "Nombre",
      price: "Precio",
      availability: "Disponibilidad",
      action: "Accion",
      in_stock: "Disponible",
      continue_shopping: "Continuar comprando",
      check_out: "Pagar",
      whishList_empty: "Lista de deseos vacia",
      explore: "Explore more shortlist  items.",

      related_products: "Productos relacionados",

      seen_all: " Ups! Ya lo ha visto todo",
      couldnt_find: "Lo sentimos! No pudimos encontrar lo que busca",
      check_misspelt:
        " Por favor ,comprueba si esta bien escrito o prueba con otra palabra",
      description: "Descripcion",
      no_description: "Sin descripcion",
      compare_empty: "Lista de compracion vacia",

      thank_you: "Gracias",
      payment_received: "El pago ha sido realizado satisfactoriamente",
      transaction_id: "Transaccion ID:",
      order_details: "Detalles del pedido",
      subtotal: "Subtotal",
      shipping: "Gastos de envio",
      tax: "tax(GST)",
      total: "total",
      payment_information: "Informacion del pago",
      payer_id: "Cliente ID:",
      payment_id: "Pago ID:",
      payment_token: "PAgo Token:",
      order_id: "Pedido ID:",
      oder_date: "Pedido Fecha:",
      order_total: "Importe Total",
      shipping_address: "Direccion de envio",
      contact_phone: "Telefono del Contacto:",
      payment_method: "Metodo de Pago:",
      banking_acceptance:
        "Aceptabilidad de la transaccion esta sujeta a la disponibilidad del dispositivo",
      delivery_date: "Fecha estimada de la entrega",

      total_price: "Precio total:",

      empty_cart: "Your Cart is Empty",

      card_details: "Detalles de la cesta",

      introduce_credit: "Introduce los detalles de la tarjeta",

      expiration: "Fecha de expiracion",
      cvc: "CVC",
      pay: "Pagar",

      billing: "Detalles del recibo",
      first_name: "Nombre",
      last_name: "Apellidos",
      phone: "Telefono",
      email_address: "Correro electronico",
      country: "Pais",
      address: "Direccion",
      city: "Town/City",
      state: "Provincia",
      postal: "Codigo postal",
      create_question: "Crear cuenta?",
      free_shipping: "Envio gratuito",
      local_pickup: "Recoger en tienda",

      submit: "enviar",

      subscribe_newsletter: "Subscribete a nuestras ofertas",
      about_text:
        "Alimentacion Del Prado lleva decadas produciendo y  comercializando productos de la mas alta calidad cumpliendo  con los mal altos estandares. Ademas de distribuir otros productos avalados por sellos de   garantia.",
      information: "Informacion",
      safe_buy: "Compra segura",

      address_text:
        " Del Prado Alimentacion c/ Armando Palacio Valdés , 10  (45,67 km) 33600 Mieres",

      language: "Idioma",
      spanish: "Espanol",
      english: "Ingles",
      currency: "Divisa",

      page_not_found: "Pagina no encontrada",
      back_home: "Vuelta a inicion",

      update_parameter: "Actualizar parametros de la cuenta",
      user_name: "Nombre del usuario",

      contact_us: "Contactanos",
      electronic_contact: "Direccion electronica",
      issue: "Asunto",
      message_input: "Escribe aqui tu mensage",

      buy_safely: "Realice la compra online de manera simple y segura",
      follow_steps: "Siga estos 3 sencillos pasos ....",
      select_products: "Selecciona los productos",
      realize_payment: "Realice el pago",
      recive_products: "Reciba los productos",

      new_customers: "Cliente nuevos",
      create_account: "Create A Account",
      create_account_text:
        " Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.",
      password: "Contrasena",
      forget_question: "Olvidaste la contrasena?",
    },
  },
};

export default module;
